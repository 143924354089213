import { Badge, Button, Card, Grid, Group, Text } from '@mantine/core';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { listSources } from '../../loaders';
import * as API from "../../core/api";
import styles from './media-library.module.scss';
import Uploader from './uploader';
import { queryClient } from '../../App';

interface Source {
    id: string,
    key: string,
    type: string,
    size: number,
    duration: number,
    ready_for_editor: boolean,
    ready_for_export: boolean,
    thumbnail?: string,
    created: string,
    metadata: {
        title?: string,
        channel?: string,
    },
}

function describe(item: Source) {
    const fragments: string[] = [];

    fragments.push(getTypeString(item.type));

    if (item.metadata.channel) {
        fragments.push(item.metadata.channel);
    }

    if (item.duration) {
        fragments.push(formatDuration(item.duration));
    }

    if (item.size) {
        fragments.push(formatSize(item.size));
    }

    return fragments.join(' | ');
}

function getTypeString(type: string) {
    switch (type) {
        case "upload":
        case "video-upload":
            return "Uploaded Video";
        case "twitch-clip":
            return "Twitch Clip";
        default:
            return "Unknown Media";
    }
}

function formatDuration(input: number) {
    let seconds = input;

    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    if (minutes) {
        return `${minutes}m${Math.ceil(seconds)}s`;
    } else {
        return `${Math.ceil(seconds)}s`;
    }
}

const KB_1 = 1024;
const MB_1 = KB_1 * 1024;
const GB_1 = MB_1 * 1024;

function formatSize(size: number) {
    let sz = size;

    const gb = Math.floor(sz / GB_1);
    sz -= gb * GB_1;

    const mb = Math.floor(sz / MB_1);
    sz -= mb * MB_1;

    const kb = Math.floor(sz / KB_1);
    sz -= kb * KB_1;

    const bytes = sz;

    if (gb >= 1) {
        const fraction = Math.ceil(mb / 1024 * 10);
        return gb + "." + fraction + " GB";
    }

    if (mb >= 1) {
        const fraction = Math.ceil(kb / 1024 * 10);
        return mb + "." + fraction + " MB";
    }

    if (kb >= 1) {
        const fraction = Math.ceil(bytes / 1024 * 10);
        return kb + "." + fraction + " KB";
    }

    return bytes + " B";
}

export default function MediaLibrary(props: { allowAdding?: boolean, add?: any }) {
    const { data, isLoading } = useQuery(["sources"], listSources(),
        { enabled: true, refetchInterval: 5000 });

    const [ addingClip, setAddingClip ] = useState(false);

    const addClip = useCallback(async () => {
        setAddingClip(true);
        const id = prompt("Enter clip ID");
        const items = await API.get("sources/register", { ids: id });
        queryClient.invalidateQueries(["sources"]);
        setAddingClip(false);
    }, []);

    const now = Date.now();

    return <div className={styles.container}>
        <div className={styles.header}>
            <h2>Media Library</h2>
            <Uploader />
            <Button size="xs" onClick={addClip} loading={addingClip}>Import Clips</Button>
        </div>
        <Grid>
            {data?.items?.map((item: Source) => {
                const processing = !item.ready_for_editor;
                const created = new Date(item.created).getTime();
                const failed = processing && (now - created) > 120000;

                let label = processing ? "Processing" : "Ready to Use";

                if (failed) {
                    label = "Import Failed";
                }

                if (props.allowAdding) {
                    label = "Add";
                }

                return <Grid.Col span={4}>
                    <Card shadow="sm" padding="lg" style={{ height: '100%', display:'flex', flexDirection: 'column' }}>
                        <Card.Section className={styles.thumbnail}>
                            {item.thumbnail && <img src={item.thumbnail} alt="" />}
                        </Card.Section>

                        <Group position="apart" style={{ marginBottom: 5, marginTop: 10 }}>
                            <Text weight={500} className={styles.ellipsize}>
                                {item.metadata.title || item.key}
                            </Text>
                        </Group>

                        <Text size="sm" style={{ lineHeight: 1.5, flexGrow: 1 }}>
                            {describe(item)}
                        </Text>

                        <Button variant="light" 
                                color={!failed ? "blue" : "red"}
                                fullWidth 
                                disabled={processing || !props.allowAdding}
                                loading={processing && !failed}
                                onClick={() => props.add(item.id)}
                                style={{ marginTop: 14 }}>
                            {label}
                        </Button>
                    </Card>
                </Grid.Col>
            })}
        </Grid>
    </div>
}