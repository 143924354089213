import { IEditorPageContext } from ".";
import { TimelineScene } from "../timeline/types";

export function getVideoListFromSourceList(context: IEditorPageContext) {
    return context.state.spec.scenes
        .map(scene => ({
            scene,
            source: context.sources[scene.baseSourceID],
        }))
        .filter(s => !!s.source)
        .map(s => ({
            id: s.scene.id,
            url: "https://dhk3xqanveto2.cloudfront.net/" + s.source.id + "-small.mp4",
            duration: s.source.duration ?? 0,
        }));;
}

export function getTimelineScenes(context: IEditorPageContext): TimelineScene[] {
    return context.state.spec.scenes
        .map(scene => {
            const source = context.sources[scene.baseSourceID];
            return {
                id: scene.baseSourceID,
                start: 0, // TODO remove property from TimelineScene?
                title: source?.title,
                thumbnail: source?.thumbnail,
                duration: source?.duration ?? 1,
                transitionDuration: 1, // FIXME
                objects: scene.objects.map(o => ({
                        ...o,
                        start: o.startTime,
                })),
            };
        });
}