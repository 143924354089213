import { Button } from "@mantine/core";
import { useState } from "react";
import {Uppy, UppyFile} from "@uppy/core";
import {DashboardModal, useUppy} from "@uppy/react";
import { getTusdEndpoint } from "../../core/api";
import Tus from "@uppy/tus";

import styles from "./uploader.module.scss";
import { Upload16 } from "@carbon/icons-react";
import { queryClient } from "../../App";

export default function Uploader(props: { onUploadComplete?: any }) {
    const [open, setOpen] = useState(false);
    
    const uppy = useUppy(() => {
        const options = {
            restrictions: {
                maxFileSize: 512 * 1024 * 1024,
                minFileSize: 1024,
                maxTotalFileSize: 512 * 1024 * 1024,
                allowedFileTypes: ["video/*"],
            },
        };

        return new Uppy(options)
            // .on('complete', () => props.onUploadComplete?.())
            .use(Tus, {endpoint: getTusdEndpoint()});
    });

    if (!uppy) {
        return;
    }

    const isOpen = open;

    return (
        <div className={styles.container} key={"uploader--" + open}>
            <Button size={"xs"}
                    leftIcon={<Upload16 />}
                    onClick={() => setOpen(true)}>Upload</Button>
            <DashboardModal uppy={uppy}
                            closeModalOnClickOutside={true}
                            onRequestClose={() => {
                                queryClient.invalidateQueries(["sources"]);
                                setOpen(false);
                            }}
                            open={isOpen}
                            showLinkToFileUploadResult={false}
                            proudlyDisplayPoweredByUppy={false} />
        </div>
    );
}