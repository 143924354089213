import { useCallback, useContext, useMemo, useState } from "react";
import { EditorPageContext } from ".";
import { EditorPageProps } from "./page";
import { getVideoListFromSourceList } from "./utils";

import styles from './export.module.scss';
import Shell from "../layout2/shell2";
import { Accordion, Button, Progress, Text } from "@mantine/core";
import { useInterval } from "react-use";
import { queryClient } from "../../App";
import * as API from "../../core/api";
import { loadConnection } from "../../loaders";
import { useQuery } from "react-query";

export interface DbExport {
    id: string,
    created: string,
    status: string,
    duration: number,
    publishes: any,
}

export interface PreparedExport {
    id: string,
    created: number,
    status: string,
    runtimeMinutes: number,
    duration: number,
    publishes: any,
}

function processExports(exports: DbExport[]): PreparedExport[] {
    if (!exports?.length) {
        return [];
    }
    const now = Date.now();
    return exports.map(e => {
        const time = new Date(e.created).getTime();
        const runtimeMinutes = (now - time) / 60000;
        let status = e.status.toLocaleLowerCase();
        if (status === 'active' && runtimeMinutes > 10) {
            status = "error";
        }
        if (runtimeMinutes < 0) {
            status = "error";  // just in case
        }
        return {
            id: e.id,
            created: time,
            status,
            runtimeMinutes,
            duration: e.duration,
            publishes: e.publishes,
        };
    });
}

function describePublishState(e: PreparedExport) {
    const publish = e.publishes?.[0];
    if (!publish) {
        return "You have not yet published this video to YouTube.";
    }
    switch (publish.status) {
        case "PENDING":
            return "Publishing video to YouTube... please wait...";
        case "SUCCESS":
            return "You have successfully published this video on YouTube.";
        case "ERROR":
            return "Something went wrong while publishing this video on YouTube.";
        default:
            return "Unknown publish status, something went wrong with streamloops.";
    }
}

function canUpload(e: PreparedExport) {
    const publish = e.publishes?.[0];
    if (!publish) {
        return true;
    }
    switch (publish.status) {
        case "PENDING":
            return false;
        case "SUCCESS":
            return false;
        case "ERROR":
            return true;
        default:
            return true;
    }
}

export default function Export(props: EditorPageProps) {
    const context = useContext(EditorPageContext);
    const [time, setTime] = useState(0);

    useInterval(() => setTime(Date.now), 1000);

    const { data: connection } = useQuery(["connection", "youtube"], loadConnection("youtube"), 
        { enabled: true, refetchInterval: 5000 });

    const hasScenes = !!context.state.spec.scenes?.length;

    const currentSceneSpec = context.state.spec.scenes[context.state.ui.selectedSceneIndex];
    const currentSceneBaseSourceID = currentSceneSpec?.baseSourceID;
    const currentSceneBaseSource = context.sources[currentSceneBaseSourceID];

    const videos = useMemo(() => getVideoListFromSourceList(context),
        [context.state.spec.modifiedAt, Object.keys(context.sources).length]);

    const exportVideo = useCallback(async () => {
        await API.createExport(context.projectID, true, context.state.spec);
        queryClient.invalidateQueries("exports");
    }, [context.projectID, context.state?.spec]);

    const connectYouTube = useCallback(() => {
        window.open(API.getConnectUrl("youtube"), "_blank");
    }, []);

    const publish = useCallback(async (exportID: string) => {
        await API.publishExport(exportID);
    }, []);

    const exports = processExports(context.exports);
    const activeExport = exports.filter(e => e.status === "active")[0];
    const hasActiveExport = !!activeExport;

    let progress = hasActiveExport ? Math.min(90, (activeExport.runtimeMinutes / 5 * 100)) : 0;
    
    return <Shell variant="no-sidebar" header={{
        title: "Return to project",
        backURL: "/project/" + context.projectID
    }}>
        <div className={styles.container}>
            <section>
                <div className={styles.header}>
                    <h2>Export Video</h2>
                </div>
                {!hasActiveExport && (
                    <div className={styles.export}>
                        {/* <p style={{ marginBottom: '.618rem' }}>Video Length: 20:04</p> */}
                        <Button onClick={exportVideo}>Begin Export</Button>
                    </div>
                )}
                {hasActiveExport && (
                    <div className={styles.export}>
                        Your video is being exported.
                        <Progress value={progress} animate />
                    </div>
                )}
            </section>
            <section>
                <div className={styles.header}>
                    <h3>Previous Exports</h3>
                </div>
                <Accordion offsetIcon={false}>
                    {exports.map(e => (
                        <Accordion.Item label={new Date(e.created).toString() + " -- " + e.status} key={e.id}>
                            {e.status === 'success' && (
                                (connection?.connected ? (<>
                                    <Text>Connected to {connection.data?.title ?? "unknown channel"} on YouTube.</Text>
                                    {e.publishes?.length ? (
                                        <Text>{describePublishState(e)}</Text>
                                    ) : <Button disabled={!canUpload(e)} onClick={() => publish(e.id)}>Publish to YouTube</Button>}
                                </>) : (
                                    <Button onClick={connectYouTube}>Connect YouTube account</Button>
                                ))
                            )}
                            {e.status === 'error' && "Export failed."}
                        </Accordion.Item>
                    ))}
                </Accordion>
            </section>
        </div>
    </Shell >
}