import styles from './editor.module.scss';
import { Project } from '../../types/project';
import { useCallback, useContext, useMemo, useState } from 'react';
import Properties from './properties';
import Timeline from '../timeline/timeline';
import { ActionIcon, Modal, Text, TextInput, useMantineColorScheme, useMantineTheme } from '@mantine/core';
import { EditorPageContext } from '.';
import Player from '../player';
import { getTimelineScenes, getVideoListFromSourceList } from './utils';
import EditorPageToolbox from './toolbox';
import ObjectProperties from './object-properties';
import { SpecActionType } from '../../core/reducer';
import { queryClient } from '../../App';
import * as API from "../../core/api";
import { useToggle } from 'react-use';
import Edit16 from '@carbon/icons-react/lib/edit/16';
import Checkmark32 from '@carbon/icons-react/lib/checkmark/32';
import Shell from '../layout2/shell2';
import Upgrade from '../upgrade';
import MediaLibrary from '../media-library';
import { useNavigate } from 'react-router-dom';

export interface EditorPageProps {
    project: Project,
}

export default function EditorPageInner(props: EditorPageProps) {
    const { colorScheme } = useMantineColorScheme();
    const theme = useMantineTheme();
    const navigate = useNavigate();

    const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
    const [upgradeOpen, setUpgradesOpen] = useState(false);
    
    const context = useContext(EditorPageContext);

    const hasScenes = !!context.state.spec.scenes?.length;

    const currentSceneSpec = context.state.spec.scenes[context.state.ui.selectedSceneIndex];
    const currentSceneBaseSourceID = currentSceneSpec?.baseSourceID;
    const currentSceneBaseSource = context.sources[currentSceneBaseSourceID];

    const videos = useMemo(() => getVideoListFromSourceList(context),
        [context.state.spec.modifiedAt, Object.keys(context.sources).length]);
    const timelineScenes = getTimelineScenes(context);

    const hasActiveExport = !!context.exports?.filter(e => e.status === "ACTIVE").length;

    const addClip = useCallback((sourceID: string) => {
        // const id = prompt("Enter clip ID");
        // const items = await API.get("sources/register", { ids: id });
        // const sourceID = items[0].id;
        context.dispatch({
            type: SpecActionType.AddScene,
            value: sourceID,
        });
    }, []);

    const exportVideo = useCallback(async () => {
        // await API.createExport(context.projectID, true, context.state.spec);
        // queryClient.invalidateQueries("exports");
        navigate(`/project/${context.projectID}/export`);
    }, []);

    const [editingTitle, toggleEditingTitle] = useToggle(false);

    const title = useMemo(() => {
        if (!editingTitle) {
            return <>
                <h1><Text>{context.state.spec.title || "Untitled Project"}</Text></h1>
                <ActionIcon size="lg" variant="hover" onClick={() => toggleEditingTitle()}>
                    <Edit16 />
                </ActionIcon>
            </>;
        } else {
            return <>
                <TextInput value={context.state.spec.title}
                    onChange={e => context.dispatch({
                        type: SpecActionType.SetProjectTitle,
                        value: e.target.value,
                    })} />
                <ActionIcon size="lg" variant="hover" onClick={() => toggleEditingTitle()}>
                    <Checkmark32 />
                </ActionIcon>
            </>
        }
    }, [context.state?.spec?.title])

    return (
        <Shell
            header={{
                title,
                actionButtons: [
                    { label: "Add Clips", onClick: () => setMediaLibraryOpen(true) },
                    { label: "Export Video", onClick: exportVideo },
                ],
            }}
            navbar={(
                <div className={styles.sidebar}>
                    <EditorPageToolbox />
                    <Properties key={context.state.ui.selectedObject?.objectID}>
                        <ObjectProperties />
                    </Properties>
                </div>
            )}>
                <div className={styles.container}>
            <div className={styles.player}>
                {hasScenes && <Player videos={videos}
                    spec={context.state.spec}
                    selectedObject={context.state.ui.selectedObject}
                    currentTime={context.state.ui.currentTime}
                    seek={context.state.ui.seek}
                    dispatch={context.dispatch} />}
                {!hasScenes && <div className={styles.empty}>
                    <Text>Click the "Add Clip" button to get started.</Text>
                    {context.exports?.length > 0 && JSON.stringify(context.exports)}
                </div>}
            </div>
            <Timeline theme={colorScheme}
                className={styles.timeline}
                dispatch={context.dispatch}
                sources={context.sources}
                scenes={context.state.spec.scenes}
                currentTime={context.state.ui.currentTime}
                selectedObject={context.state.ui.selectedObject} />
            <Modal
                opened={upgradeOpen}
                onClose={() => setUpgradesOpen(false)}
                title="Upgrade"
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.95}
            >
                <Upgrade description="Upgrade to a paid plan to publish your video:" />
            </Modal>
            <Modal
                opened={mediaLibraryOpen}
                size="80%"
                onClose={() => setMediaLibraryOpen(false)}
                title="Media Library"
                overflow="inside"
                overlayColor={theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2]}
                overlayOpacity={0.85}
            >
                <MediaLibrary allowAdding={true} add={addClip} />
            </Modal>
            </div>
        </Shell>
    );
}