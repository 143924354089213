import { useEffect, useMemo } from 'react';
import { AppShell, Avatar, Button, Group, Navbar, ScrollArea, Text, ThemeIcon, UnstyledButton, useMantineColorScheme } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import AppHeader, { HeaderProps } from './header';
import styles from './shell.module.scss'
import ChevronRight16 from '@carbon/icons-react/lib/chevron--right/16';
import Checkmark32 from '@carbon/icons-react/lib/checkmark/16';
import Warning16 from '@carbon/icons-react/lib/warning/16';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../../core/session';
import { Folder16 } from '@carbon/icons-react';

export interface ShellProps {
    header: HeaderProps,
    navbar?: any,
    className?: string,
    children?: any,
    blended?: boolean,
}

export default function Shell(props: ShellProps) {
    const navigate = useNavigate();
    const [session, loading] = useSession();
    const { colorScheme } = useMantineColorScheme();
    const notifications = useNotifications();

    useEffect(() => {
        if (!session?.user?.id) {
            return;
        }

        const planID = session?.plan?.id as string;
        const planName = planID[0].toLocaleUpperCase() + planID.substring(1);

        if (window.location.search.includes("checkout=success")) {
            notifications.showNotification({
                id: "plan",
                autoClose: false,
                title: "Your account has been upgraded",
                message: `You're now subscribed to the ${planName} plan. Enjoy!`,
                icon: <Checkmark32 />,
            });
        } else if (window.location.search.includes("checkout=cancelled")) {
            if (planID === "free") {
                notifications.showNotification({
                    id: "plan",
                    autoClose: 7000,
                    title: "Your upgrade wasn't finished.",
                    message: `Upgrade to a paid plan to export your videos.`,
                    icon: <Warning16 />,
                    color: 'red',
                });
            } else {
                notifications.showNotification({
                    id: "plan",
                    autoClose: 7000,
                    title: "Your upgrade wasn't finished.",
                    message: `You didn't complete the upgrade process. You're still subscribed to the ${planName} plan.`,
                    icon: <Warning16 />,
                    color: 'red',
                });
            }
        }
    }, [session?.user?.id]);

    const header = useMemo(
        () => <AppHeader {...props.header} />,
        [colorScheme, props.header.title, props.header.backURL, props.header.isHome,
            props.header.actionButtons?.length, props.header.menu?.length]
    );

    const navbar = useMemo(
        () => {
            if (!props.navbar) {
                return null;
            }
            return <Navbar width={{ base: '25%' }} height={'100%'} padding="xl">
                {/* <Navbar.Section>Top</Navbar.Section> */}
                <Navbar.Section grow component={ScrollArea}>
                    <div className={styles.sidebarNav}>
                        <Group direction="column">
                            <a href="#">
                                <Group>
                                    <ThemeIcon radius="xl">
                                        <Folder16 />
                                    </ThemeIcon>
                                    <span>Your Videos</span>
                                </Group>
                            </a>
                            <a href="#">
                                <Group>
                                    <ThemeIcon radius="xl">
                                        <Folder16 />
                                    </ThemeIcon>
                                    <span>Media Library</span>
                                </Group>
                            </a>
                        </Group>
                    </div>
                </Navbar.Section>
                <Navbar.Section>
                    <Button variant="white" className={styles.userButton} onClick={() => navigate("/account")}>
                        <Group direction="row">
                            <Avatar size={40} radius="xl" color="blue">CH</Avatar>
                            <Group direction="column" spacing={0} style={{ flexGrow: 1 }}>
                                <Text weight={"bold"} style={{ lineHeight: 1.4 }}>{session?.user.name}</Text>
                                <Text size="xs" style={{ lineHeight: 1.4, opacity: 0.7 }}>{session?.user.email}</Text>
                            </Group>
                            <ChevronRight16 />
                        </Group>
                    </Button>
                </Navbar.Section>
            </Navbar>;
        },
        [session?.user?.id, props.navbar]
    );

    if (!session?.user?.id) {
        return null;
    }

    const classes = [styles.container];

    if (props.className) {
        classes.push(props.className);
    }

    if (props.blended) {
        classes.push(styles.blended);
    }

    return <AppShell
        padding="xl"
        className={classes.join(" ")}
        styles={(theme) => ({
            main: {
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                '--border-color': theme.colorScheme === 'dark' ? '#333333' : '#cccccc',
            },
        })}
        navbar={navbar}
        header={header}>
        {props.children}
    </AppShell>
}