import React, { useState } from 'react';
import Helmet from 'react-helmet';
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import { ColorScheme, ColorSchemeProvider, MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import './App.scss';
import ProjectListPage from './components/project-list';
import { BrowserRouter } from "react-router-dom";
import EditorPage from './components/editor';
import AccountPage from './components/account';

import "react-contexify/dist/ReactContexify.css";
import StripePage from './components/stripe';
import { useSession } from './core/session';

export const queryClient = new QueryClient();

function App() {
  // const [session, loading] = useSession();
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');

  const toggleColorScheme = (value?: ColorScheme) =>
    setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));

  // if (session && !session.userID) {
  // return null;
  // }

  return (
    <>
      <Helmet>
        <title>StreamLoops</title>
      </Helmet>
      <QueryClientProvider client={queryClient}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider theme={{ colorScheme }}>
            <NotificationsProvider>
              <BrowserRouter>
                <Routes>
                  <Route index element={<ProjectListPage />} />
                  <Route path="project/:projectID/export" element={<EditorPage export={true} />} />
                  <Route path="project/:projectID" element={<EditorPage />} />
                  <Route path="account" element={<AccountPage />} />
                  <Route path="stripe" element={<StripePage />} />
                </Routes>
              </BrowserRouter>
            </NotificationsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </>
  );
}

export default App;
