import Money16 from "@carbon/icons-react/lib/money/16";
import Password16 from "@carbon/icons-react/lib/password/16";
import User16 from "@carbon/icons-react/lib/user/16";
import EnvelopeClosedIcon from "@carbon/icons-react/lib/email/16";
import { Input, Text, Button, Container, Paper, Progress, Radio, RadioGroup, Switch, Tabs, Title, InputWrapper } from "@mantine/core";

import styles from './account-page.module.scss';
import planStyle from './plans.module.scss';
import { PasswordStrength } from "./password";
import { useSession } from "../../core/session";
import { useCallback, useState } from "react";

import * as API from '../../core/api';
import Shell from "../layout2/shell";

export default function AccountPage(props: any) {
    const [session, loading] = useSession();
    const [name, setName] = useState<string|undefined>(undefined);
    const [email, setEmail] = useState<string|undefined>(undefined);
    const [password, setPassword] = useState<string>("");

    const saveProfile = useCallback(async () => {
        const query = {
            name: name ?? session.user.name,
            email: email ?? session.user.email,   
        }
        await API.get('user/update-profile', query);
    }, [session?.user.name, session?.user.email, name, email]);

    const savePassword = useCallback(async () => {
        if (!password) {
            return;
        }
        await API.post('user/update-password', { password });
    }, [session?.user.name, password]);

    if (loading) {
        return <div>Loading</div>;
    }

    const planID = session?.plan?.id as string;
    const planName = planID[0].toLocaleUpperCase() + planID.substring(1);

    return <div className={styles.container}>
        <Shell header={{ title: "Return to streamloops app" }}>
            <Container size="md" padding={0}>
                <Paper padding={0} shadow="xs" radius="sm" withBorder>
                    <Tabs orientation="vertical" tabPadding={0}>
                        <Tabs.Tab label="Plans & Billing" icon={<Money16 />}>
                            <div className={planStyle.container}>
                                <Title order={2}>{planName} Plan</Title>
                                <Text>You have exported <strong>{session.usage.totalExportMinutesUsed} minutes</strong> of video this month. You have <strong>{session.usage.totalExportMinutesRemaining} minutes</strong> remaining.</Text>
                                <Text>Your current plan ({planName}) includes {session.plan.limits.exportMinutes} minutes of exported video per month and will reset on 2/20/2021.</Text>
                                <Progress className={planStyle.progressBar} 
                                          value={session.usage.totalExportMinutesUsed / session.plan.limits.exportMinutes * 100} 
                                          label={session.usage.totalExportMinutesUsed + " minutes used"}
                                          size="xl" 
                                          radius="xl" />

                                {/* <Title order={2}>Upgrade Plan</Title> */}

                                <RadioGroup
                                    variant="vertical"
                                    label="Change Plan"
                                    description={<Text>Upgrade your plan to unlock features. See the pricing page for more information.</Text>}
                                    spacing="xl"
                                    size="md">
                                    <Radio value="basic" disabled={planID === "basic"}>
                                        Basic {planID === "basic" && "(Current)"} <em>$9 / month</em>
                                    </Radio>
                                    <Radio value="professional" disabled={planID === "professional"}>
                                        Professional {planID === "professional" && "(Current)"} <em>$29 / month</em>
                                    </Radio>
                                    <Radio value="premium" disabled={planID === "premium"}>
                                        Premium {planID === "premium" && "(Current)"} <em>$79 / month</em>
                                    </Radio>
                                </RadioGroup>
                                <div className={planStyle.buttonBar}>
                                    {/* <Switch label="Annual Billing" /> */}
                                    <Button>Select Plan</Button>
                                </div>
                            </div>
                        </Tabs.Tab>
                        <Tabs.Tab label="Profile" icon={<User16 />}>
                            <Title order={2}>Account Profile</Title>
                            <InputWrapper
                                required
                                label="Your Name">
                                <Input
                                    icon={<User16 />}
                                    onChange={e => setName(e.target.value)}
                                    value={name ?? session.user.name}
                                    placeholder="Your name" />
                            </InputWrapper>
                            <InputWrapper
                                style={{ marginTop: '1rem' }}
                                required
                                label="Your Email Address">
                                <Input
                                    icon={<EnvelopeClosedIcon />}
                                    onChange={e => setEmail(e.target.value)}
                                    value={email ?? session.user.email}
                                    placeholder="Your email" />
                            </InputWrapper>
                            <Button onClick={saveProfile} style={{ marginTop: '1rem' }}>Save</Button>
                        </Tabs.Tab>
                        <Tabs.Tab label="Security" icon={<Password16 />}>
                            <Title order={2}>Account Password</Title>
                            <PasswordStrength value={password} onValueChange={setPassword} />
                            <Button onClick={savePassword} style={{ marginTop: '1rem' }}>Update Password</Button>
                        </Tabs.Tab>
                    </Tabs>
                </Paper>
            </Container>
        </Shell>
    </div>;
}