import { Button, Radio, RadioGroup, Text } from "@mantine/core";
import { useCallback, useState } from "react";
import { useSession } from "../../core/session";
import * as API from "../../core/api";
import styles from '../account/plans.module.scss';

export default function Upgrade(props: any) {
    const [session, loading] = useSession();

    const [value, setValue] = useState(null);
    const [clicked, setClicked] = useState(false);

    const submit = useCallback(async () => {
        setClicked(true);

        const result = await API.get("user/update-plan", {
            plan: value,
            redirect: `https://${window.location.host}/`,
        });

        window.location.href = result.url;
    }, [clicked, setClicked, value]);

    const planID = session?.plan?.id as string;
    const planName = planID[0].toLocaleUpperCase() + planID.substring(1);

    return <div className={styles.container}>
        <RadioGroup
            value={value}
            onChange={setValue}
            variant="vertical"
            description={<Text>{props.description ?? "Upgrade your plan to unlock features."}</Text>}
            spacing="xl"
            size="md">
            <Radio value="basic" disabled={planID === "basic"}>
                <strong>Basic {planID === "basic" && "(Current)"} <span>200 minutes each month</span></strong> 
                <em>$9 / month</em>
            </Radio>
            <Radio value="professional" disabled={planID === "professional"}>
                <strong>Professional {planID === "professional" && "(Current)"} <span>500 minutes each month</span></strong> 
                <em>$29 / month</em>
            </Radio>
            <Radio value="premium" disabled={planID === "premium"}>
                <strong>Premium {planID === "premium" && "(Current)"} <span>Unlimited</span></strong> 
                <em>$79 / month</em>
            </Radio>
        </RadioGroup>
        <div className={styles.buttonBar}>
            <Button onClick={submit} disabled={!value} loading={clicked} fullWidth>Upgrade</Button>
        </div>
    </div>;
}