import { useQuery } from 'react-query';
import { loadProjects } from '../../loaders';
// import Shell from '../layout/shell';
import styles from './project-list.module.scss';
// import {Button, ExpandableSearch, Pagination, Search, Tile, TooltipIcon} from "carbon-components-react";
import { Project } from '../../types/project';
import { useCallback, useState } from 'react';
import * as API from '../../core/api';
import { queryClient } from '../../App';
import { AppShell, Button, Navbar, Group, Text, UnstyledButton, ScrollArea, ThemeIcon, Avatar } from '@mantine/core';
import AppHeader from '../layout2/header';
import Shell from '../layout2/shell2';
import { Link, useNavigate } from 'react-router-dom';
import { Folder16, Video16, MediaLibrary16 } from '@carbon/icons-react';
import ChevronRight16 from '@carbon/icons-react/lib/chevron--right/16';
import Checkmark32 from '@carbon/icons-react/lib/checkmark/16';
import Warning16 from '@carbon/icons-react/lib/warning/16';
import { useSession } from '../../core/session';
import MediaLibrary from '../media-library';

export interface ProjectListPageProps {
}

export default function ProjectListPage(props: ProjectListPageProps) {
    const navigate = useNavigate();
    const [session, loading] = useSession();
    const [section, setSection] = useState("media-library");

    const { data, isLoading } = useQuery(["projects"], loadProjects(),
        { enabled: true });

    const create = useCallback(async () => {
        const result = await API.createProject("Untitled");
        queryClient.invalidateQueries("projects");
        navigate(`/project/${result.id}`);
    }, []);

    if (!session?.user?.id) {
        return null;
    }

    const logo = <h1 className={styles.logo}>
        <span>stream</span><strong>loops</strong>
    </h1>;

    const navbar = <div className={styles.sidebar}>
        <div className={styles.sidebarNav}>
            <a href="#" className={section === 'videos' ? styles.active : ""} onClick={() => setSection("videos")}>
                <Group>
                    <ThemeIcon radius="xl">
                        <Video16 />
                    </ThemeIcon>
                    <span>Your Videos</span>
                </Group>
            </a>
            <a href="#" className={section === 'media-library' ? styles.active : ""} onClick={() => setSection("media-library")}>
                <Group>
                    <ThemeIcon radius="xl">
                        <MediaLibrary16 />
                    </ThemeIcon>
                    <span>Media Library</span>
                </Group>
            </a>
        </div>
        <Button variant="white" className={styles.userButton} onClick={() => navigate("/account")}>
            <Group direction="row">
                <Avatar size={40} radius="xl" color="blue">CH</Avatar>
                <Group direction="column" spacing={0} style={{ flexGrow: 1 }}>
                    <Text weight={"bold"} style={{ lineHeight: 1.4 }}>{session?.user.name}</Text>
                    <Text size="xs" style={{ lineHeight: 1.4, opacity: 0.7 }}>{session?.user.email}</Text>
                </Group>
                <ChevronRight16 />
            </Group>
        </Button>
    </div>;

    return (
        <Shell header={{ isHome: true, actionButtons: [{ label: "Create Video", onClick: create }] }} navbar={navbar} variant={"sidebar-logo"}>
            {section === 'videos' && (
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1>Your Videos</h1>
                        {/* <Button size="sm" onClick={create}>Create Video</Button> */}
                    </div>
                    {data?.itemCount && <ul className={styles.list}>
                        {data.items.map((item: Project, index: number) => (
                            <li className={styles.project} key={item.id + "--" + index}>
                                <Link to={`/project/${item.id}`}>
                                    <img src={item.thumbnail} alt="" />
                                    {item.title}
                                </Link>
                            </li>
                        ))}
                    </ul>}
                    {/* <div className={styles.footer}>
                        <Pagination onChange={() => {}}
                                    page={1}
                                    size={"lg"}
                                    totalItems={data?.itemCount || 0}
                                    pageSizes={[10, 25, 50, 100]} />
                    </div> */}
                </div>
            )}
            {section === 'media-library' && (
                <div style={{ margin: '2rem' }}>
                    <MediaLibrary />
                </div>
            )}
        </Shell>
    );
}