import { useEffect, useMemo } from 'react';
import { AppShell, Avatar, Button, Group, Navbar, ScrollArea, Text, ThemeIcon, UnstyledButton, useMantineColorScheme } from '@mantine/core';
import { useNotifications } from '@mantine/notifications';
import AppHeader, { HeaderProps } from './header';
import styles from './shell.module.scss'
import ChevronRight16 from '@carbon/icons-react/lib/chevron--right/16';
import Checkmark32 from '@carbon/icons-react/lib/checkmark/16';
import Warning16 from '@carbon/icons-react/lib/warning/16';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../../core/session';
import { Folder16 } from '@carbon/icons-react';

export interface ShellProps {
    header: HeaderProps,
    navbar?: any,
    className?: string,
    children?: any,
    blended?: boolean,
    variant?: string,
}

export default function Shell(props: ShellProps) {
    const { colorScheme } = useMantineColorScheme();

    const classes = [styles.v2];

    classes.push(colorScheme === 'light' ? styles.light : styles.dark);

    if (props.variant === 'sidebar-logo') {
        classes.push(styles.withSidebar);
    } else if (props.variant === 'no-sidebar') {
        classes.push(styles.noSidebar);
    } else {
        classes.push(styles.standard);
    }

    return <div className={classes.join(" ")}>
        <div className={styles.logo}>
            <h1>
                <span>stream</span><strong>loops</strong>
            </h1>
        </div>
        <AppHeader {...props.header} className={styles.header} />
        <div className={styles.sidebar}>
            {props.navbar}
        </div>
        <main className={styles.main}>
            {props.children}
        </main>
    </div>;
}