"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var coordinates_1 = require("./coordinates");
var ObjectRenderer = /** @class */ (function () {
    function ObjectRenderer(width, height, fabric) {
        this.width = width;
        this.height = height;
        this.fabric = fabric;
        this.coordinates = new coordinates_1.Coordinates(fabric, { width: width, height: height });
    }
    ObjectRenderer.prototype.render = function (object, sceneCurrentTime, sceneDuration, canvas) {
        if (this.active(object, sceneCurrentTime)) {
            var createdObjects = this.renderFrame(object, sceneCurrentTime, sceneDuration, canvas);
            return {
                active: true,
                createdObjects: createdObjects,
            };
        }
        else {
            return {
                active: false,
                createdObjects: 0,
            };
        }
    };
    ObjectRenderer.prototype.renderFrame = function (object, sceneCurrentTime, sceneDuration, canvas) {
        // no-op in base class
        console.log("Warning - no renderer found for object type (".concat(object.type, "), using default renderer."));
        return 0;
    };
    ObjectRenderer.prototype.active = function (object, sceneCurrentTime) {
        if (sceneCurrentTime < object.startTime) {
            return false;
        }
        var end = object.startTime + object.duration;
        if (sceneCurrentTime >= end) {
            return false;
        }
        return true;
    };
    ObjectRenderer.prototype.getAbsoluteCoordinates = function (x, y) {
        return {
            x: this.width * x,
            y: this.height * y,
        };
    };
    ObjectRenderer.prototype.getPercentCoordinates = function (x, y) {
        return {
            x: x / this.width,
            y: y / this.height,
        };
    };
    return ObjectRenderer;
}());
exports.default = ObjectRenderer;
