import ArrowLeft16 from '@carbon/icons-react/lib/arrow--left/16';
import Light16 from '@carbon/icons-react/lib/light/16';
import Moon16 from '@carbon/icons-react/lib/moon/16';
import GearIcon from '@carbon/icons-react/lib/settings/16';
import ExitIcon from '@carbon/icons-react/lib/logout/16';
import { Button, Menu, Divider, useMantineColorScheme, Header, ActionIcon, SegmentedControl } from '@mantine/core';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';

import styles from "./header.module.scss";
import { signOut } from '../../core/session';

export interface HeaderProps {
    className?: string,
    title?: any,
    backURL?: string,
    actionButtons?: ActionButton[],
    menu?: Record<string, MenuItem[]>,
    isHome?: boolean,
}

export interface ActionButton {
    label: string,
    onClick?: any,
    icon?: any,
    loading?: boolean,
}

export interface MenuItem {
    label: string,
    onClick?: any,
    icon?: any,
}

export function _AppHeader(props: HeaderProps) {
    const { colorScheme, toggleColorScheme } = useMantineColorScheme();
    const [editingTitle, toggleEditingTitle] = useToggle(false);
    const navigate = useNavigate();

    const back = useCallback(() => {
        navigate(props.backURL ? props.backURL : "/");
    }, [props.backURL])

    const classes = [styles.container];

    if (props.className) {
        classes.push(props.className);
    }

    return (
        <header className={classes.join(" ")}>
            <div className={styles.wrapper}>
                {!props.isHome && <ActionIcon size="lg" variant="hover" onClick={back}>
                    <ArrowLeft16 />
                </ActionIcon>}

                {!!props.title && <div className={styles.title}>
                    {props.title}
                </div>}

                <div style={{ display: 'flex', alignItems: 'center', flexGrow: '1' }} />

                <SegmentedControl value={colorScheme}
                    transitionDuration={500}
                    transitionTimingFunction="linear"
                    size="xs"
                    onChange={() => toggleColorScheme()}
                    data={[
                        { label: "Light", value: "light" },
                        { label: "Dark", value: "dark" },
                    ]} />

                <div className={styles.actionButtons}>


                    {/* <Button onClick={() => toggleColorScheme()} key={"theme"} variant="outline" size="sm">
                        {colorScheme === "light" ? <Moon16 /> : <Light16 />}
                    </Button> */}

                    {props.actionButtons?.length > 0 && props.actionButtons.map(btn => (
                        <Button size="sm"
                            loading={btn.loading}
                            onClick={() => btn.onClick()}
                            key={btn.label}>
                            {btn.label}
                        </Button>
                    ))}
                </div>

                <Menu withArrow>
                    {!!props.menu && Object.keys(props.menu).map((label: string) => <>
                        <Menu.Label>{label}</Menu.Label>
                        {props.menu[label].map((item: MenuItem) => (
                            <Menu.Item icon={item.icon} onClick={() => item.onClick?.()}>
                                {item.label}
                            </Menu.Item>
                        ))}
                        <Divider />
                    </>)}

                    <Menu.Label>Account</Menu.Label>
                    <Menu.Item icon={<GearIcon />} onClick={() => navigate("/account")}>
                        Account Settings
                    </Menu.Item>
                    <Menu.Item icon={<ExitIcon />} onClick={() => signOut()}>Sign Out</Menu.Item>
                </Menu>
            </div>
        </header>
    );
}

export const AppHeader = React.memo(_AppHeader);

export default AppHeader;