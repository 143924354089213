import { useCallback, useEffect, useReducer, useRef, useState } from 'react';
import TimelineScene from './scene';
import styles from './timeline.module.scss';

import TimelineToolbar from './toolbar';
import TimelineObjectContextMenu from './object-context-menu';
import TimelineSceneList from './scene-list';
import TimelineSceneListItem from './scene-list-item';
import TimelineTimeHandle from './time-handle';
import { stateReducer, TimelineActionType } from './reducer';
import { internalStateReducer, TimelineInternalActionType } from './reducer/internal';
import { TimelineCurrentTime, TimelineProps, TimelineState } from './types';
import { getProjectTimeFromSceneTime, mergeScenesWithSources } from './utils';
import TimelineSceneContextMenu from './scene-context-menu';

export default function Timeline(props: TimelineProps) {
    const [_state, _dispatchAction] = useReducer(stateReducer, props);

    // allow parent component to control state (optional)
    const state = props.dispatch ? props : _state;
    const dispatchAction = props.dispatch || _dispatchAction;
    const scenes = mergeScenesWithSources(state.scenes, props.sources);

    const [internalState, dispatchInternalAction] = useReducer(internalStateReducer, {
        pxWidthPerSecond: 15,
    });

    const ref = useRef<HTMLDivElement>(null);

    const zoomIn = useCallback(() => dispatchInternalAction({ type: TimelineInternalActionType.ZoomIn }), []);
    const zoomOut = useCallback(() => dispatchInternalAction({ type: TimelineInternalActionType.ZoomOut }), []);
    const onObjectChanging = useCallback((sceneID: string, objectID: string) => {
        dispatchInternalAction({ 
            type: TimelineInternalActionType.ChangingObject, 
            sceneID, 
            objectID, 
        });
    }, []);
    const setCurrentTime = useCallback((time: TimelineCurrentTime) => {
        dispatchAction({ 
            type: TimelineActionType.SetCurrentTime, 
            value: time,
        });
    }, []);
    const onSceneMove = useCallback((from: number, to: number) => {
        dispatchAction({ 
            type: TimelineActionType.MoveScene, 
            value: { from, to },
        });
    }, []);
    const onStartEditingTitle = useCallback((sceneID: string, editing: boolean = true) => {
        dispatchInternalAction({ 
            type: TimelineInternalActionType.StartEditingTitle, 
            sceneID: editing ? sceneID : null,
        });
    }, []);
    const setSceneTitle = useCallback((sceneID: string, title: string) => {
        dispatchAction({ 
            type: TimelineActionType.SetSceneTitle, 
            sceneID,
            value: title,
        });
    }, []);

    const { currentTime, duration } = getProjectTimeFromSceneTime(scenes, state.currentTime);

    const classes = [styles.timeline];
    if (props.className) {
        classes.push(props.className);
    }

    return <div ref={ref} id="timeline" className={classes.join(' ')} data-theme={(props as TimelineProps).theme ?? "light"}>
        <TimelineToolbar time={currentTime}
            duration={duration}
            zoomIn={zoomIn}
            zoomOut={zoomOut} />
        <TimelineTimeHandle scenes={scenes} 
                            currentTime={state.currentTime} 
                            pxWidthPerSecond={internalState.pxWidthPerSecond}
                            setCurrentTime={setCurrentTime} />
        <TimelineSceneList onSceneMove={onSceneMove}>
            {scenes.map((scene: any, index: number) => (
                <TimelineSceneListItem id={scene.id} index={index}>
                    <TimelineScene scene={scene}
                        selectedObjectID={state.selectedObject?.sceneID === scene.id ? state.selectedObject.objectID : null}
                        index={index}
                        pxWidthPerSecond={internalState.pxWidthPerSecond}
                        movingObjectID={internalState.movingObjectID} 
                        editingTitle={internalState.editingTitleForSceneID === scene.id}
                        onStartEditingTitle={onStartEditingTitle}
                        setSceneTitle={setSceneTitle}
                        dispatch={dispatchAction}
                        setCurrentTime={setCurrentTime}
                        onObjectChanging={onObjectChanging} />
                </TimelineSceneListItem>
            ))}
        </TimelineSceneList>
        <TimelineSceneContextMenu dispatchAction={dispatchAction} scenes={scenes} />
        <TimelineObjectContextMenu dispatchAction={dispatchAction} />
    </div>;
}