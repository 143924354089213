import { ProjectSpec } from "../types/spec";

function getApiUrl() {
    if (process.env.NODE_ENV === "production") {
        return "/api";
    } else if (window.location.hostname.includes(".gitpod.io")) {
        return "https://" + window.location.hostname.replace("3000", "3001") + "/api";
    } else {
        return "/unknown-api-endpoint";
    }
}

export function getConnectUrl(service: string) {
    return getApiUrl() + "/connect/" + encodeURIComponent(service);
}

export function getTusdEndpoint() {
    // if (process.env.NODE_ENV === "production") {
    //     return "/files";
    // } else if (window.location.hostname.includes(".gitpod.io")) {
    //     return "https://" + window.location.hostname.replace("3000", "1080") + "/files";
    // } else {
    //     return "/unknown-tusd-endpoint";
    // }   
    return getApiUrl() + "/upload";
}

export async function getSession() {
    const url = getApiUrl() + "/session?r=" + Date.now();
    const response = await fetch(url, {
        headers: {
            "Accept": "application/json",
        },
    });
    if (response.status === 401) {
        return { signedIn: false, session: null };
    }
    if (response.status === 200) {
        return { signedIn: true, session: await response.json() };
    }
    throw "Invalid response";
}

export async function get(endpoint: string, query: Record<string, any> = {}) {
    const queryString = Object.keys(query)
        .map(key => key + '=' + encodeURIComponent(query[key]))
        .join('&');
    const url = getApiUrl() + "/" 
        + (endpoint.startsWith("/") ? endpoint.slice(1) : endpoint) 
        + '?' + queryString;
    const response = await fetch(url, {
        headers: {
            "Accept": "application/json",
        },
    });
    return await response.json();
}

export async function post(endpoint: string, query: Record<string, any> = {}) {
    const url = getApiUrl() + "/" 
        + (endpoint.startsWith("/") ? endpoint.slice(1) : endpoint);
    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify(query),
        headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
}

export async function registerSources(ids: string[]) {
    return await get("sources/register", { 
        ids: ids.join(","),
    });
}

export async function save(projectID: string, spec: ProjectSpec) {
    return await get("projects/update", { 
        id: projectID,
        title: spec.title || "",
        spec: JSON.stringify(spec),
    });
}

export async function deleteProject(id: string) {
    return await get("projects/delete", { id });
}

export async function createProject(title: string) {
    return await get("projects/create", { title });
}

export async function createExport(projectID: string, fast: boolean, spec: any) {
    return await get("exports/create", { 
        projectID, 
        fast, 
        title: spec?.title || "Untitled Video",
        spec: JSON.stringify(spec),
    });
}

export async function publishExport(exportID: string) {
    return await get("exports/publish", { 
        exportID
    });
}