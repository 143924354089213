import Forward_1032 from "@carbon/icons-react/lib/forward--10/32";
import PauseOutline32 from "@carbon/icons-react/lib/pause--outline/32";
import PlayOutline32 from "@carbon/icons-react/lib/play--outline/32";
import Rewind_1032 from "@carbon/icons-react/lib/rewind--10/32";
import SkipBackOutlineSolid32 from "@carbon/icons-react/lib/skip--back--outline--solid/32";
import SkipForwardOutlineSolid32 from "@carbon/icons-react/lib/skip--forward--outline--solid/32";

import { ActionIcon } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";
import VideoManager from "../../core/video-manager";
import { Video } from "../../core/video-manager/types";
import { EditorPageAction } from "../editor/reducer/ui";
import { fabric } from "fabric";

import styles from './player.module.scss';
import { ProjectSpec } from "../../types/spec";
import Renderer from "@streamloops/renderer/build/renderer";
import canvas from "../../core/video-manager/canvas";
import { SpecActionType } from "../../core/reducer";

export interface Props {
    videos: Video[],
    spec: ProjectSpec,
    selectedObject?: any,
    currentTime: any,
    seek: any,
    dispatch: any,
}

export default function Player(props: Props) {
    const playingRef = useRef(false);
    const [playing, setPlaying] = useState(false);
    const [ready, setReady] = useState(false);
    const main = useRef<HTMLDivElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const fabricCanvasRef = useRef<any>(null);
    const frameCounter = useRef(0);

    const manager = useRef<VideoManager>(null);
    const renderer = useRef<any>(null);

    useEffect(() => {
        renderer.current = new Renderer();

        if (!manager.current) {
            manager.current = new VideoManager(false, onFrame);
        }

        return () => {
            manager.current?.destroy();
        };
    }, [])

    const initializeCanvasOnFirstFrame = useCallback((canvas: HTMLCanvasElement) => {
        fabricCanvasRef.current = new fabric.Canvas(canvasRef.current, {
            backgroundColor: "rgba(0, 0, 0, 1)",
        });

        const fabricCanvas = fabricCanvasRef.current as fabric.Canvas;
        fabricCanvas.on("mouse:down", () => manager.current.pause());
        fabricCanvas.on("object:modified", (event: any) => {
            if (event.target?.slObjectID) {
                const objectID = event.target.slObjectID;
                const coordinates = {
                    originX: event.target.originX,
                    originY: event.target.originY,
                    left: event.target.left / canvas.width,
                    top: event.target.top / canvas.height,
                    width: event.target.width / canvas.width,
                    height: event.target.height / canvas.height,
                    zoomX: event.target.zoomX,
                    zoomY: event.target.zoomy,
                    scaleX: event.target.scaleX,
                    scaleY: event.target.scaleY,
                    angle: event.target.angle,
                };

                props.dispatch({
                    type: SpecActionType.SetObjectCoordinates,
                    target: objectID,
                    value: coordinates,
                });

                if (event.target.text) {
                    props.dispatch({
                        type: SpecActionType.SetObjectText,
                        target: objectID,
                        value: event.target.text,
                    });
                }

                console.log(event, objectID, coordinates);

                renderer.current.clearCache();
            }
        });

        fabricCanvas.setDimensions({ width: canvas.width, height: canvas.height });

        const image = new fabric.Image(canvas as any, {
            left: 0,
            top: 0,
            originX: "left",
            originY: "top",
            lockMovementX: true,
            lockMovementY: true,
            lockRotation: true,
            lockScalingX: true,
            lockScalingY: true,
            hasControls: false,
            selectable: false,
            lockScalingFlip: true,
            lockSkewingX: true,
            lockSkewingY: true,
            lockUniScaling: true,
            hoverCursor: "default",
            objectCaching: false,
        });

        fabricCanvas.add(image);
        fabricCanvas.sendToBack(image);
    }, []);

    const renderObjects = useCallback(() => {
        if (!renderer.current || !fabricCanvasRef.current) {
            return; // wait for initialization
        }
        renderer.current.setSelectedObject(!playing ? props.selectedObject?.objectID : null);
        renderer.current.renderCurrentScene(props.currentTime.sceneCurrentTime, 
            fabricCanvasRef.current);
    }, [props.spec.modifiedAt, props.selectedObject?.objectID, props.currentTime?.sceneCurrentTime, playing]);

    const onFrame = useCallback((canvas, currentTime, video, videoCurrentTime, playing) => {
        if (!fabricCanvasRef.current) {
            initializeCanvasOnFirstFrame(canvas);
        }

        main.current.style.marginLeft = `calc(50% - ${canvasRef.current.offsetWidth / 2}px)`;
        // canvasRef.current.offsetWidth;

        const scene = props.spec.scenes.filter(s => s.id === props.currentTime.sceneID)[0];
        renderer.current.setCurrentScene(scene, video.duration, canvas.width, canvas.height);

        frameCounter.current += 1;

        if (frameCounter.current > 5 || playingRef.current !== playing) {
            frameCounter.current = 0;
            props.dispatch({
                type: EditorPageAction.SetCurrentTime,
                value: {
                    sceneID: video.id,
                    sceneCurrentTime: videoCurrentTime,
                },
            });
        }

        if (playingRef.current !== playing) {
            playingRef.current = playing;
            setPlaying(playing);
            renderer.current.clearCache();
        }
        
        if (!ready) {
            setReady(true);
        }

        renderObjects();
    }, [renderObjects, ready]);

    useEffect(() => {
        if (manager.current) {
            manager.current.onFrame = onFrame;
        }
    }, [onFrame])

    useEffect(() => {
        manager.current.setVideos(props.videos);
    }, [props.videos]);

    useEffect(() => {
        if (props.seek) {
            manager.current.seekToVideo(props.seek.sceneID, props.seek.sceneCurrentTime);
        }
    }, [props.seek?.sceneID, props.seek?.sceneCurrentTime]);

    return <div className={styles.container} data-ready={ready ? 'true' : 'false'}>
        <div className={styles.main} ref={main}>
            <canvas ref={canvasRef} />
        </div>
        <div className={styles.toolbar}>
            <ActionIcon size="lg" onClick={() => manager.current?.seekToStart()}><SkipBackOutlineSolid32 /></ActionIcon>
            <ActionIcon size="lg" onClick={() => manager.current?.seekBack(10)}><Rewind_1032 /></ActionIcon>
            <ActionIcon size="lg" onClick={() => {
                manager.current?.togglePlayState();
                playingRef.current = manager.current?.playing() || false;
                setPlaying(playingRef.current);
            }}>
                {playing ? <PauseOutline32 /> : <PlayOutline32 />}
            </ActionIcon>
            <ActionIcon size="lg" onClick={() => manager.current?.seekForward(10)}><Forward_1032 /></ActionIcon>
            <ActionIcon size="lg" onClick={() => manager.current?.seekToEnd()}><SkipForwardOutlineSolid32 /></ActionIcon>
        </div>
    </div>;
}